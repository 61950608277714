<template>
  <v-container>
    <v-row>
      <v-col class="mb-6 mb-sm-8" cols="12" lg="7">
        <h1 class="page-title mb-5 mb-sm-10">Контакты</h1>
        <div class="d-flex align-center mb-8">
          <img class="mr-5 flex-shrink-0" src="../assets/img/svg/dish.svg" alt="" width="77" height="101">
          <div>
            <p class="text-14 mb-0">Время работы и приема заказов:</p> 
            <p class="text-22 mb-0"><b>с 9:00 до 19:00</b></p>
          </div>
        </div>
        <v-row class="text-14">
          <v-col cols="12" sm="6">
            <div class="d-flex mb-6">
              <div class="mr-4 flex-shrink-0 d-flex justify-center align-start w-20">
                <img src="../assets/img/svg/pin.svg" alt="" width="16" height="20">
              </div>
              <span>Москва, 105082, <br> Переведеновский пер., д. 18, <br> стр. 3, пом. I, этаж 1</span>
            </div>
            <div class="d-flex mb-6">
              <div class="mr-4 flex-shrink-0 d-flex justify-center w-20">
                <img src="../assets/img/svg/mail.svg" alt="" width="20" height="16">
              </div>
              <a class="black--text text-decoration-none" href="mailto:info@louvreelegance.com">info@louvreelegance.com</a>
            </div>
            <div class="d-flex mb-6">
              <div class="mr-4 flex-shrink-0 d-flex justify-center w-20">
                <img src="../assets/img/svg/phone.svg" alt="" width="20" height="20">
              </div>
              <a class="black--text text-decoration-none" href="tel:+74956624881">+7 495 66 24 881</a>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <p class="mb-0">ООО "Лувр Элеганс"</p>
            <p class="mb-0">ИНН 7726636536</p>
            <p class="mb-0">ОГРН 1097746555455</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mb-6 mb-sm-8" cols="12" lg="5">
        <div class="map">
          <img src="../assets/img/map.png" alt="" width="390" height="390">
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {

  }
</script>

<style>

</style>