<template>
  <v-container>
    <section class="profile">
      <div class="d-flex align-center justify-space-between mb-8">
        <h1 class="page-title mr-3">Профиль</h1>
        <ul class="list-inline justify-end">
          <li class="mr-0 ml-5">
            <a @click.prevent="activeTab = 'favorites'" :class="{active: activeTab === 'favorites'}"
               href="#">Избранное</a>
          </li>
          <li class="mr-0 ml-5">
            <a @click.prevent="activeTab = 'history'" :class="{active: activeTab === 'history'}" href="#">История
              заказов</a>
          </li>
        </ul>
      </div>
      <template v-if="activeTab === 'favorites'">
        <v-row class="cards-row">
          <v-col cols="12" sm="6" lg="4" v-for="item in getFavoriteProducts" :key="item.id">
            <app-card v-bind="item"/>
          </v-col>
        </v-row>
        <div class="text-center" v-if="getFavoriteProducts.length === 0">
          <img class="mb-4" src="../assets/img/svg/empty_favorites.svg" width="182" height="174" alt="">
          <p class="text-body-2 text-gray mb-6">Вы еще не добавили ничего в избранное...</p>
          <router-link :to="{ name: 'Index' }">
            <v-btn
                class="btn-primary-md mb-5"
                :disabled="false"
                x-large
                color="primary"
                rounded
            >
              Перейти в каталог
            </v-btn>
          </router-link>
        </div>
      </template>
      <template v-if="activeTab === 'history'">
        <template v-for="item in getUserOrders">
          <AppHistoryRecord v-bind="item"></AppHistoryRecord>
        </template>
      </template>
    </section>
  </v-container>
</template>

<script>
import AppHistoryRecord from '@/components/app-history-record.vue'
import AppCard from '@/components/app-card.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Profile',
  components: {
    AppHistoryRecord,
    AppCard,
  },
  data() {
    return {
      activeTab: 'history',
    }
  },
  created() {
    if (this.allProducts.length === 0) {
      this.getProducts()
    }
    this.getOrdersAction()
    this.getFavoritesAction()
  },
  methods: {
    ...mapActions(['getOrdersAction', 'getProducts', 'getFavoritesAction']),
  },
  computed: {
    ...mapGetters(['allProducts', 'getFavoriteProducts', 'getUserOrders']),
  },
}
</script>
