<template>
  <div class="history-record">
    <div class="d-flex align-center">
      <span class="text-body-1 mr-5">{{ formattedOrderDate }}</span>
      <span class="text-17 text-primary">{{ total_price + ' руб.' }}</span>
      <span class="text-17 text-primary ms-4">{{ orderStatus }}</span>
      <v-btn class="history-record__dropdown-icon ml-auto" icon @click="open = !open" :class="{'rotate': open}">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M5.91985 9.48625C6.31037 9.09573 6.94354 9.09573 7.33406 9.48625L12.627 14.7791L17.9198 9.48625C18.3104 9.09573 18.9435 9.09573 19.3341 9.48625C19.7246 9.87678 19.7246 10.5099 19.3341 10.9005L13.3341 16.9005C12.9435 17.291 12.3104 17.291 11.9198 16.9005L5.91985 10.9005C5.52932 10.5099 5.52932 9.87678 5.91985 9.48625Z"
              fill="#0D0D0D"/>
        </svg>
      </v-btn>
    </div>
    <slide-up-down :active="open" :duration="300">
      <template v-for="item in products">
        <div class="history-record__item">
          <div class="history-record__image-wr">
            <img :src="img(item)" alt="">
          </div>
          <div class="history-record__info">
            <h3 class="history-record__title">{{ item.title }}</h3>
            <span class="history-record__price">{{ priceFormat(item.price) }}</span>
          </div>
          <span class="history-record__amount">{{ item.order_quantity + ' шт.' }}</span>
          <span class="history-record__price-full">{{ priceFormat(item.price * item.order_quantity) }}</span>
        </div>
      </template>
    </slide-up-down>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      open: false,
    }
  },
  props: {
    dt_created: String,
    total_price: Number,
    products: Array,
    order_status: String
  },
  computed: {
    ...mapGetters(['getUserOrders']),
    formattedOrderDate() {
      let date = new Date(this.dt_created.replace(/-/g, "/"))
      let day = date.getDate()
      if (day < 10) day = '0' + day
      let month = date.getMonth() + 1
      if (month < 10) month = '0' + month
      return day + '.' + month + '.' + date.getFullYear()
    },
    priceFormat() {
      return price => price === 0 ? 0 : price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' руб.';
    },
    img() {
      return item => {
        try {
          return require(`../assets/img/${ item.img }`);
        } catch {
          return require(`../assets/img/card_default.png`);
        }
      }
    },
    orderStatus() {
      if (this.order_status === 'allow') return 'Оплачен'
      if (this.order_status === 'decline') return 'Отменен'
      if (this.order_status === 'shipped') return 'Отправлен'
      if (this.order_status === 'finished') return 'Завершен'
    }
  },
}
</script>
