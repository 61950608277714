<template>
  <div class="page-wrap">
    <v-app>
      <v-main>
        <app-header/>
        <router-view/>
        <app-footer/>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import AppHeader from '@/components/app-header.vue'
import AppFooter from '@/components/app-footer.vue'

export default {
  name: 'App',
  data: () => ({
    //
  }),
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
