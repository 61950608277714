<template>
  <v-container>
    <app-filter/>
    <section class="product" v-if="product">
      <v-row>
        <v-col cols="12" md="5" lg="5" class="mr-lg-5">
          <img class="product__image" :src="product.img" alt="" @error="e => e.target.src = require('../assets/img/card_default.png')">
        </v-col>
        <v-col cols="12" md="7" lg="6">
          <div class="product__info">
            <h1 class="product__title">{{ product.title }}</h1>
            <p class="product__weight">{{ product.weight + ' г' }}</p>
            <div class="d-flex mb-3">
              <div class="d-flex align-center mr-auto">
                <v-btn class="product__count-btn" icon @click="productQuantity--">
                  <span>—</span>
                </v-btn>
                <input class="product__count-input" v-model:value="productQuantity" value='3' type="text" readonly>
                <v-btn class="product__count-btn" icon @click="productQuantity++">
                  <span>+</span>
                </v-btn>
              </div>
              <div class="d-flex align-center">
                <span class="product__price">{{ priceFormat(product.price) }}</span>
                <span class="product__price-old">
                  {{ product.old_price === 0 ? '' : priceFormat(product.old_price) }}
                </span>
              </div>
            </div>
            <div class="f-flex mb-2">
              <v-btn
                  class="btn-add-more mr-2 mb-2"
                  elevation="0"
                  rounded
                  small
                  @click="productQuantity += 5"
              >+5
              </v-btn>
              <v-btn
                  class="btn-add-more mr-2 mb-2"
                  elevation="0"
                  rounded
                  small
                  @click="productQuantity += 10"
              >+10
              </v-btn>
              <v-btn
                  class="btn-add-more mr-2 mb-2"
                  elevation="0"
                  rounded
                  small
                  @click="productQuantity += 15"
              >+15
              </v-btn>
            </div>
            <div class="product__desc" v-html="product.description">
<!--              <p>{{ product.description }}</p>-->
            </div>
            <div class="product__dropdown mb-3">
              <div class="d-flex align-center mb-1">
                <h5 class="subtitle-1 font-weight-medium mr-3">Cостав</h5>
                <v-btn class="product__dropdown-icon ml-auto" icon @click="toggleDropdown('Composition')"
                       :class="{'rotate': showComposition}">
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.91985 9.48625C6.31037 9.09573 6.94354 9.09573 7.33406 9.48625L12.627 14.7791L17.9198 9.48625C18.3104 9.09573 18.9435 9.09573 19.3341 9.48625C19.7246 9.87678 19.7246 10.5099 19.3341 10.9005L13.3341 16.9005C12.9435 17.291 12.3104 17.291 11.9198 16.9005L5.91985 10.9005C5.52932 10.5099 5.52932 9.87678 5.91985 9.48625Z"
                        fill="#0D0D0D"/>
                  </svg>
                </v-btn>
              </div>
              <slide-up-down :active="showComposition" :duration="300">
                <div class="product__dropdown-hidden" v-html="product.consist">
<!--                  {{ product.consist }}-->
                </div>
              </slide-up-down>
            </div>
            <div class="product__dropdown mb-3">
              <div class="d-flex align-center mb-1">
                <h5 class="subtitle-1 font-weight-medium mr-3">Энергетическая ценность</h5>
                <v-btn class="product__dropdown-icon ml-auto" icon @click="toggleDropdown('Energy')"
                       :class="{'rotate': showEnergy}">
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.91985 9.48625C6.31037 9.09573 6.94354 9.09573 7.33406 9.48625L12.627 14.7791L17.9198 9.48625C18.3104 9.09573 18.9435 9.09573 19.3341 9.48625C19.7246 9.87678 19.7246 10.5099 19.3341 10.9005L13.3341 16.9005C12.9435 17.291 12.3104 17.291 11.9198 16.9005L5.91985 10.9005C5.52932 10.5099 5.52932 9.87678 5.91985 9.48625Z"
                        fill="#0D0D0D"/>
                  </svg>
                </v-btn>
              </div>
              <slide-up-down :active="showEnergy" :duration="300">
                <div class="product__dropdown-hidden">
                  {{ product.energy_value + ' кДж' }}
                </div>
              </slide-up-down>
            </div>
          </div>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AppFilter from '@/components/app-filter.vue'

export default {
  name: 'Product',
  components: {AppFilter},
  data() {
    return {
      showComposition: true,
      showEnergy: false,
      product_id: Number(this.$route.params.product_id),
    }
  },
  created() {
    if (this.allProducts.length === 0) {
      this.getProducts()
    }
  },
  methods: {
    ...mapActions(['setOrderAction', 'getProducts', 'setQuantityAction']),
    toggleDropdown(item) {
      this['show' + item] = !this['show' + item]
    },
  },
  computed: {
    ...mapGetters(['allProducts', 'getOrder']),
    productQuantity: {
      get() {
        if (this.getOrder.length === 0) return 0;

        let index = this.getOrder.findIndex(elem => elem.product_id === this.product_id);
        return index === -1 ? 0 : this.getOrder[index].quantity;
      },
      set(value) {
        if (value >= 0) {
          this.setOrderAction({
            product: this.product_id,
            quantity: value,
            price: this.product.price,
            weight: this.product.weight,
          })
          if (value === 0) {
            let index = this.getOrder.findIndex(elem => elem.product_id === this.product_id)
            this.setQuantityAction({
              index: index
            })
          }
        }
      },
    },
    product() {
      return this.allProducts.find(item => item.id === this.product_id)
    },
    priceFormat() {
      return price => price === 0 ? 0 : price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' руб.';
    },
  },
}
</script>
