<template>
  <section class="filter">
    <v-row align="center">
      <v-col cols="12" lg="9">
        <ul v-if="$vuetify.breakpoint.name === 'xl'" class="list-inline filter__categories">
          <li v-for="(elem, key) in allCategories" :key="elem.id">
            <a href="#"
               @click.prevent="setCat(key); setActiveCategory(key)"
               :class="{active : isActive === elem.id}"
            >{{ elem.text }}</a>
          </li>
        </ul>
        <template v-else>
          <div class="d-flex align-center">
            <v-select
                class="custom-select mr-2"
                v-model="selectType"
                :items="allCategories"
                hide-details
                solo
                @change="setCatMobile(selectType)"
                :value="selectType"
            ></v-select>
            <v-btn
                class="custom-icon-btn"
                icon
                @click="toggleFilter"
            >
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5 2C4.44772 2 4 2.44772 4 3C4 3.55228 4.44772 4 5 4C5.55228 4 6 3.55228 6 3C6 2.44772 5.55228 2 5 2ZM2.17071 2C2.58254 0.834808 3.69378 0 5 0C6.30622 0 7.41746 0.834808 7.82929 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H7.82929C7.41746 5.16519 6.30622 6 5 6C3.69378 6 2.58254 5.16519 2.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H2.17071ZM11 8C10.4477 8 10 8.44772 10 9C10 9.55229 10.4477 10 11 10C11.5523 10 12 9.55229 12 9C12 8.44772 11.5523 8 11 8ZM8.17071 8C8.58254 6.83481 9.69378 6 11 6C12.3062 6 13.4175 6.83481 13.8293 8H15C15.5523 8 16 8.44772 16 9C16 9.55229 15.5523 10 15 10H13.8293C13.4175 11.1652 12.3062 12 11 12C9.69378 12 8.58254 11.1652 8.17071 10H1C0.447715 10 0 9.55229 0 9C0 8.44772 0.447715 8 1 8H8.17071ZM5 14C4.44772 14 4 14.4477 4 15C4 15.5523 4.44772 16 5 16C5.55228 16 6 15.5523 6 15C6 14.4477 5.55228 14 5 14ZM2.17071 14C2.58254 12.8348 3.69378 12 5 12C6.30622 12 7.41746 12.8348 7.82929 14H15C15.5523 14 16 14.4477 16 15C16 15.5523 15.5523 16 15 16H7.82929C7.41746 17.1652 6.30622 18 5 18C3.69378 18 2.58254 17.1652 2.17071 16H1C0.447715 16 0 15.5523 0 15C0 14.4477 0.447715 14 1 14H2.17071Z"
                    fill="#e61873"/>
              </svg>
            </v-btn>
          </div>
        </template>
      </v-col>
      <v-col lg="3">
        <div class="filter__wr-mob" :class="{'open': mobFilterOpen}">
          <h5 class="text-h5 mb-10 d-block d-lg-none">Фильтр</h5>
          <a class="close ml-3 d-flex d-lg-none" @click="toggleFilter">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                  fill="black"/>
            </svg>
          </a>
          <v-row class="justify-lg-end">
<!--            <v-col cols="12" lg="7" class="mb-10 mb-lg-0">-->
<!--              <div class="filter__range">-->
<!--                <v-range-slider-->
<!--                    class="custom-range"-->
<!--                    hide-details-->
<!--                    v-model="range"-->
<!--                    :min="min"-->
<!--                    :max="max"-->
<!--                    @change="setPriceFilter"-->
<!--                ></v-range-slider>-->
<!--                <div class="filter__range-nums">-->
<!--                  <span>{{ min }}</span>-->
<!--                  <span>{{ max * 0.25 }}</span>-->
<!--                  <span>{{ max * 0.5 }}</span>-->
<!--                  <span>{{ max * 0.75 }}</span>-->
<!--                  <span>{{ max }}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </v-col>-->
            <v-col cols="12" class="mb-12 mb-lg-0">
              <div class="filter__sort">
                <v-select
                    class="custom-select"
                    v-model="selectSort"
                    :items="selectSortItems"
                    hide-details
                    solo
                    @change="setPriceFilter"
                ></v-select>
              </div>
            </v-col>
          </v-row>
<!--          <v-btn-->
<!--              rounded-->
<!--              color="primary"-->
<!--              elevation="0"-->
<!--              class="btn-primary d-block d-lg-none"-->
<!--          >Применить-->
<!--          </v-btn>-->
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  data() {
    return {
      min: 0,
      max: 100000,
      range: [0, 1000000],
      mobFilterOpen: false,
      selectSort: {id: 1, text: 'По убыванию цены', value: 1},
      selectSortItems: [
        {id: 1, text: 'По убыванию цены', value: 1},
        {id: 2, text: 'По возрастанию цены', value: 2},
        {id: 3, text: 'Cначала новинки', value: 3},
        {id: 4, text: 'Сначала по скидке', value: 4},
      ],
      selectType: {id: 1, text: 'Канапе', value: 0},
      selectTypeItems: [
        {id: 1, text: 'Канапе', value: 0},
        {id: 2, text: 'Холодные закуски', value: 1},
        {id: 3, text: 'Сэндвичи', value: 2},
        {id: 4, text: 'Выпечка', value: 3},
      ],
      isActive: null
    }
  },
  computed: {
    ...mapGetters(['allCategories'])
  },
  methods: {
    ...mapActions(['setFilterAction', 'activeCategoryAction']),
    toggleFilter() {
      this.mobFilterOpen = !this.mobFilterOpen
    },
    setCat(index) {
      this.setFilterAction({
        id: this.allCategories[index].id,
        minPrice: this.range[0],
        maxPrice: this.range[1],
        sort: this.selectSort
      })
      this.isActive = this.allCategories[index].id
      if (this.$route.name === 'Product') this.$router.push({name: 'Index'})
    },
    setPriceFilter() {
      this.setFilterAction({
        id: this.isActive,
        minPrice: this.range[0],
        maxPrice: this.range[1],
        sort: this.selectSort
      })
    },
    setActiveCategory(index) {
      this.activeCategoryAction({
        index: index
      })
    },
    setCatMobile(id) {
      this.setFilterAction({
        id: id,
        minPrice: this.range[0],
        maxPrice: this.range[1],
        sort: this.selectSort
      })
      if (this.$route.name === 'Product') this.$router.push({name: 'Index'})
    }
  }
}
</script>

<style>

</style>