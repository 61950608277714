<template>
  <v-container>
    <section>
      <h2 class="text-center font-weight-regular mb-5 mb-sm-10">Правила оплаты и доставки</h2>
      <div class="payment-text" v-html="content">
        <!-- текст -->
      </div>
    </section>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'PaymentDelivery',
    async mounted() {
      try {
        const response = await axios.get("/api/index.php?alef_action=getRules&id=1");
        const { data } = await response;
        this.content = data.content.html_content;
      } catch (e) {
        console.log(e)
      }
    },
    data() {
      return {
        content: ""
      };
    }
  }
</script>

<style>

</style>