<template>
  <v-container>
    <div
        class="info-line info-line--first"
        v-if="getTextBanner"
    >
      <img  src="../assets/img/svg/delivery.svg" alt="" width="24" height="20">
      <span>{{ getTextBanner }}</span>
    </div>
    <app-filter/>
    <div
        class="info-line info-line--second"
        v-if="getActiveCategory > 0 && allCategories[getActiveCategory].banner"
    >
      <img  src="../assets/img/svg/cooking.svg" alt="" width="24" height="24">
      <span>{{ allCategories[getActiveCategory].banner }}</span>
    </div>
    <v-row class="cards-row">
      <v-col cols="12" sm="6" lg="4" v-for="item in getFilterStatus ? sortProducts : allProducts" :key="item.id">
        <app-card v-bind="item"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppFilter from '@/components/app-filter.vue'
import AppCard from '@/components/app-card.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Index',
  components: {
    AppFilter,
    AppCard,
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions(['getProducts']),
  },
  computed: {
    ...mapGetters(['sortProducts', 'allProducts', 'getFilterStatus', 'getTextBanner', 'allCategories', 'getActiveCategory']),
  },
}
</script>
