import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    allProducts: [],
    allCategories: [],
    allProductsApi: '/api/index.php?alef_action=getProducts',
    ordersApi: '/api/index.php?alef_action=getOrders',
    favoritesApi: '/api/index.php?alef_action=getFavorites',
    registerApi: 'api/index.php?alef_action=SignUp',
    loginApi: '/api/index.php?alef_action=SignIn',
    restoreApi: '/api/index.php?alef_action=RestorePassword',
    logoutApi: '/api/index.php?alef_action=SignOut',
    appendFavoritesApi: '/api/index.php?alef_action=appendFavorites',
    setFavoritesApi: '/api/index.php?alef_action=setFavorites',
    sendOrderApi: '/api/index.php?alef_action=addOrder',
    getCartApi: '/api/index.php?alef_action=getCart',
    confirmEmailApi: '/api/index.php?alef_action=ConfirmEmail',
    newPasswordApi: '/api/index.php?alef_action=ConfirmRestorePassword',
    getUserApi: '/api/index.php?alef_action=getUser',
    lastOrderDetailsApi: '/api/index.php?alef_action=getLastOrder',
    textBannerApi: '/api/index.php?alef_action=getRules',
    sortedProducts: [],
    order: [],
    orderDetails: {
      pay: 'cash',
      name: '',
      phone: '',
      email: '',
      delivery: 'courier',
      date: '',
      time: '',
      comment: '',
      address: '',
      order_id: '',
    },
    userOrders: [],
    favorites: [],
    user: [],
    authDialogs: {
      dialogLogin: false,
      dialogReg: false,
      dialogRec: false,
      dialogPass: false,
    },
    isAuth: false,
    cart: '',
    isFilterActive: false,
    userMessages: {
      statusEmail: false,
      statusPhone: false,
      text: ''
    },
    newPassToken: '',
    textBanner: '',
    activeCategory: null,
    noAuthFavoriteId: null
  },
  mutations: {
    setFilter(state, payload) {
      state.isFilterActive = true
      if (payload.id) {
        state.sortedProducts = state.allProducts.filter(elem => {
          return elem.categories.some(item => item === payload.id) && elem.price >= payload.minPrice && elem.price <= payload.maxPrice
        })
      } else {
        state.sortedProducts = state.allProducts.filter(elem => {
          return elem.price >= payload.minPrice && elem.price <= payload.maxPrice
        })
      }
      if (payload.sort === 1) {
        state.sortedProducts.sort((a, b) => b.price - a.price)
      }
      if (payload.sort === 2) {
        state.sortedProducts.sort((a, b) => a.price - b.price)
      }
      if (payload.sort === 3) {
        state.sortedProducts.sort((a, b) => b.is_new - a.is_new)
      }
      if (payload.sort === 4) {
        state.sortedProducts.sort((a, b) => b.old_price - a.old_price)
      }
    },
    setOrder(state, payload) {
      if (payload.fromStorage) {
        state.order = payload.order
        return
      }
      if (state.order.length === 0) {
        state.order.push({
          product_id: payload.product,
          quantity: payload.quantity,
          price: payload.price,
          weight: payload.weight
        })
      } else {
        let index = state.order.findIndex(item => item.product_id === payload.product)
        if (index === -1) {
          state.order.push({
            product_id: payload.product,
            quantity: payload.quantity,
            price: payload.price,
            weight: payload.weight
          })
        } else {
          state.order[index].quantity = payload.quantity
        }
      }
      if (localStorage.getItem('order')) {
        localStorage.removeItem('order')
      }
      localStorage.setItem('order', JSON.stringify(state.order))
    },
    setQuantity(state, payload) {
      if (payload.direction === 'up') {
        state.order[payload.index].quantity += payload.amount
      }
      if (payload.direction === 'down' && state.order[payload.index].quantity > 0) {
        state.order[payload.index].quantity--
      }
      if (state.order[payload.index].quantity === 0) {
        state.order.splice(payload.index, 1)
      }
      if (localStorage.getItem('order')) {
        localStorage.removeItem('order')
      }
      localStorage.setItem('order', JSON.stringify(state.order))
    },
    setOrderDetails(state, payload) {
      // state.orderDetails = payload
      Vue.set(state.orderDetails, payload.name, payload.value)
    },
    setUser(state, payload) {
      state.user.push(payload)
      Vue.set(state.orderDetails, 'name', payload.name)
      Vue.set(state.orderDetails, 'phone', payload.phone)
      Vue.set(state.orderDetails, 'email', payload.email)
    },
    setUserMessage(state, payload) {
      Vue.set(state.userMessages, 'statusEmail', payload.statusEmail)
      Vue.set(state.userMessages, 'statusPhone', payload.statusPhone)
      Vue.set(state.userMessages, 'text', payload.text)
    },
    setTextBanner(state, payload) {
      state.textBanner = payload.text
    },
    setActiveCategory(state, payload) {
      state.activeCategory = payload.index
    },
    setNoAuthFavoriteId(state, payload) {
      state.noAuthFavoriteId = payload.id
    }
  },
  actions: {
    async getProducts(context) {
      try {
        const response = await axios.get(context.state.allProductsApi)
        const { data } = await response
        context.state.allProducts = data.products;
        context.state.allCategories = data.categories;
      } catch (e) {
        console.log(e)
      }
    },
    setFilterAction(context, payload) {
      context.commit('setFilter', payload)
    },
    setOrderAction(context, payload) {
      context.commit('setOrder', payload)
    },
    setQuantityAction(context, payload) {
      context.commit('setQuantity', payload)
    },
    setOrderDetailsAction(context, payload) {
      context.commit('setOrderDetails', payload)
    },
    async getOrdersAction(context) {
      try {
        const response = await axios.post(context.state.ordersApi)
        const { data } = await response

        context.state.userOrders = data.orders;
      } catch (e) {
        console.log(e)
      }
    },
    async getFavoritesAction(context) {
      try {
        const response = await axios.get(context.state.favoritesApi)
        const { data } = await response
        context.state.favorites = data.favorites
      } catch (e) {
        console.log(e)
      }
    },
    async setFavoritesAction(context) {
      try {
        const response = await axios.get(context.state.favoritesApi)
        const { data } = await response
        context.state.favorites = data.favorites
      } catch (e) {
        console.log(e)
      }
    },
    async appendFavoriteAction(context, payload) {
      try {
        const formData = new FormData()
        formData.append('user_favorites', JSON.stringify({
          user_id: payload.user_id,
          favorites: [
            {
              id: payload.id
            }
          ]
        }));

        const response = await axios.post(context.state.appendFavoritesApi, formData);
        const { data } = await response;
        if (data.status === 0) {
          context.state.favorites = data.favorites;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async removeFavoriteAction(context, payload) {
      try {
        const formData = new FormData()
        formData.append('user_favorites', JSON.stringify({
          user_id: payload.user_id,
          favorites: context.state.favorites.filter(fav => fav.product_id !== payload.id).map(fav => ({ id: fav.product_id }))
        }));

        const response = await axios.post(context.state.setFavoritesApi, formData);
        const { data } = await response;
        if (data.status === 0) {
          context.state.favorites = data.favorites;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async registerAction(context, payload) {
      let formData = new FormData()
      formData.append('name', payload.name)
      formData.append('email', payload.email)
      formData.append('password', payload.password)
      formData.append('phone', payload.phone)
      try {
        const response = await axios.post(context.state.registerApi, formData)
        const responseData = await response
        if (responseData.data.status === 0) {
          // localStorage.setItem('user', JSON.stringify(responseData.data.user))
          // context.commit('setUser', responseData.data.user)
          context.state.authDialogs.dialogReg = false
          // context.state.isAuth = true
          alert('Подтвердите ваш email перейдя по ссылке из письма')
        }
        if (responseData.data.status === 452) {
          context.commit('setUserMessage', {
            statusEmail: true,
            text: responseData.data.user_message
          })
        }
        if (responseData.data.status === 453) {
          context.commit('setUserMessage', {
            statusPhone: true,
            text: responseData.data.user_message
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async loginAction(context, payload) {
      let formData = new FormData()
      formData.append('login', payload.email)
      formData.append('pass', payload.password)
      try {
        const response = await axios.post(context.state.loginApi, formData)
        const responseData = await response
        if (responseData.data.status === 0) {
          if (responseData.data.user.email_verified_at === null) {
            context.state.authDialogs.dialogLogin = false
            alert('Ваш емайл не подтвержден')
            return
          }
          // localStorage.setItem('user', JSON.stringify(responseData.data.user))
          context.commit('setUser', responseData.data.user)
          context.state.authDialogs.dialogLogin = false
          context.state.isAuth = true
          if (context.state.noAuthFavoriteId) {
            await context.dispatch('appendFavoriteAction', {
              id: context.state.noAuthFavoriteId,
              user_id: responseData.data.user.id
            })
          }
        }
        if (responseData.data.status === 1100) {
          context.commit('setUserMessage', {
            statusEmail: true,
            text: responseData.data.user_message
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async restorePasswordAction(context, payload) {
      let formData = new FormData()
      formData.append('email', payload.email)
      try {
        const response = await axios.post(context.state.restoreApi, formData)
        const responseData = await response
        if (responseData.data.status === 0) {
          context.state.authDialogs.dialogRec = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    async logoutAction(context, payload) {
      try {
        await axios.post(context.state.logoutApi)
      } catch (e) {
        console.log(e)
      }
      // localStorage.removeItem('user')
      if (payload.currentRoute !== 'Index') {
        await router.push({ name: 'Index' })
      }
      context.state.user = []
      context.state.isAuth = false
      await context.dispatch('clearOrderAction')
      context.commit('setOrderDetails', {
        name: 'name',
        value: ''
      })
      context.commit('setOrderDetails', {
        name: 'phone',
        value: ''
      })
      context.commit('setOrderDetails', {
        name: 'email',
        value: ''
      })
      context.commit('setOrderDetails', {
        name: 'address',
        value: ''
      })
    },
    // setUserAction(context, payload) {
    //   context.commit('setUser', JSON.parse(payload.user))
    // },
    dialogAuthAction(context, payload) {
      context.state.authDialogs[payload.dialog] = payload.status
    },
    async checkAuthAction(context) {
      try {
        const response = await axios({
          method: 'post',
          url: context.state.getUserApi
        })
        const responseData = await response
        if (responseData.data.status === 0) {
          if (responseData.data.user.email_verified_at === null) return
          context.state.isAuth = true
          context.state.user = responseData.data.user
        } else {
          // localStorage.removeItem('user')
          context.state.user = []
          context.state.isAuth = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    async sendOrderAction(context) {
      let formData = new FormData()
      formData.append('user_order', JSON.stringify({
        user_id: context.getters.getUser.id,
        address: context.state.orderDetails.address,
        comment: context.state.orderDetails.comment,
        date: context.state.orderDetails.date,
        delivery: context.state.orderDetails.delivery,
        email: context.state.orderDetails.email,
        // email: context.getters.getUser.email,
        pay: context.state.orderDetails.pay,
        name: context.state.orderDetails.name,
        phone: context.state.orderDetails.phone,
        time: context.state.orderDetails.time,
        products: context.state.order
      }))
      try {
        const response = await axios({
          method: 'post',
          url: context.state.sendOrderApi,
          data: formData
        })
        const responseData = await response
        if (responseData.data.status === 0) {
          context.commit('setOrderDetails', {
            name: 'order_id',
            value: responseData.data.order_id
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    clearOrderAction(context) {
      localStorage.removeItem('order')
      context.state.order = []
      context.commit('setOrderDetails', {
        name: 'comment',
        value: ''
      })
      context.commit('setOrderDetails', {
        name: 'date',
        value: ''
      })
      context.commit('setOrderDetails', {
        name: 'time',
        value: ''
      })
      context.commit('setOrderDetails', {
        name: 'order_id',
        value: ''
      })
    },
    async getCartAction(context, payload) {
      try {
        const response = await axios({
          method: 'get',
          url: context.state.getCartApi,
          params: {
            order_id: payload.order_id
          }
        })
        const responseData = await response
        if (responseData.data.status === 0) {
          context.state.cart = responseData.data.cart
        }
      } catch (e) {
        console.log(e)
      }
    },
    async confirmEmailAction(context, payload) {
      try {
        const response = await axios({
          method: 'get',
          url: context.state.confirmEmailApi,
          params: {
            token: payload.token
          }
        })
        const responseData = await response
        if (responseData.data.status !== 0) console.log(responseData.data.user_message)
      } catch (e) {
        console.log(e)
      }
    },
    async newPasswordAction(context, payload) {
      const formData = new FormData()
      formData.append('token', context.state.newPassToken)
      formData.append('pass', payload.pass)
      try {
        const response = await axios({
          method: 'post',
          url: context.state.newPasswordApi,
          data: formData
        })
        const responseData = await response
        if (responseData.data.status === 0) {
          context.state.authDialogs.dialogPass = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    async lastOrderDetailsAction(context) {
      try {
        const response = await axios({
          method: 'post',
          url: context.state.lastOrderDetailsApi,
        })
        const responseData = await response
        if (responseData.data.status === 0) {
          context.commit('setOrderDetails', {
            name: 'name',
            value: responseData.data[0].name
          })
          context.commit('setOrderDetails', {
            name: 'phone',
            value: responseData.data[0].phone
          })
          // context.commit('setOrderDetails', {
          //   name: 'email',
          //   value: responseData.data[0].email,
          // })
          context.commit('setOrderDetails', {
            name: 'address',
            value: responseData.data[0].address,
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async textBannerAction(context) {
      try {
        const response = await axios({
          method: 'get',
          url: context.state.textBannerApi,
          params: {
            id: 2
          }
        })
        const responseData = await response
        if (responseData.data.status === 0) {
          context.commit('setTextBanner', {
            text: responseData.data.content.html_content
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    activeCategoryAction(context, payload) {
      context.commit('setActiveCategory', {
        index: payload.index
      })
    },
    noAuthFavoriteAction(context, payload) {
      context.commit('setNoAuthFavoriteId', {
        id: payload.id
      })
    }
  },
  modules: {},
  getters: {
    allProducts(state) {
      return state.allProducts.sort((a, b) => a.id - b.id)
    },
    allCategories(state) {
      return state.allCategories;
    },
    sortProducts(state) {
      return state.sortedProducts
    },
    getOrder(state) {
      return state.order
    },
    getSingleProduct: state => id => {
      return state.allProducts.find(item => item.id === id)
    },
    getOrderSum(state) {
      if (state.order.length === 0) return 0
      return state.order.reduce((sum, elem) => { return sum + (elem.price * elem.quantity) }, 0)
    },
    getTotalWeight(state) {
      if (state.order.length === 0) return 0
      return state.order.reduce((sum, elem) => { return sum + (elem.weight * elem.quantity) }, 0)
    },
    getFavoriteProducts(state) {
      return state.allProducts.filter(elem => state.favorites.findIndex(item => item.product_id === elem.id) >= 0)
    },
    isFavoriteProduct(state) {
      return id => state.favorites.findIndex(item => item.product_id === id) >= 0;
    },
    getOrderDetails(state) {
      return state.orderDetails
    },
    getUserOrders(state) {
      if (state.userOrders.length === 0) return 0
      return state.userOrders.sort((a, b) => b.id - a.id)
    },
    getUser(state) {
      if (state.user.length === 0) return 0
      return state.user[0]
    },
    getAuthDialogs(state) {
      return state.authDialogs
    },
    getAuthStatus(state) {
      return state.isAuth
    },
    getCart(state) {
      return state.cart
    },
    getFilterStatus(state) {
      return state.isFilterActive
    },
    getUserMessages(state) {
      return state.userMessages
    },
    getTextBanner(state) {
      return state.textBanner.replace(/<(?:.|\n)*?>/gm, '')
    },
    getActiveCategory(state) {
      return state.activeCategory
    }
  },
})
