<template>
  <v-container>
    <section>
      <h2 class="text-center font-weight-regular">Правила оплаты</h2>
      <h2 class="text-center font-weight-regular mb-5 mb-sm-10">Политика безопасности</h2>
      <div class="payment-text">
        <p class="mb-6">При оплате заказа банковской картой, обработка платежа (включая ввод номера карты) происходит на защищенной
          странице процессинговой системы, которая прошла международную сертификацию. Это значит, что Ваши
          конфиденциальные данные (реквизиты карты, регистрационные данные и др.) не поступают в интернет-магазин, их
          обработка полностью защищена и никто, в том числе наш интернет-магазин, не может получить персональные и
          банковские данные клиента. При работе с карточными данными применяется стандарт защиты информации,
          разработанный международными платёжными системами Visa и Masterсard-Payment Card Industry Data Security
          Standard (PCI DSS), что обеспечивает безопасную обработку реквизитов Банковской карты Держателя. Применяемая
          технология передачи данных гарантирует безопасность по сделкам с Банковскими картами путем использования
          протоколов Secure Sockets Layer (SSL), Verifiedby Visa, Secure Code,и закрытых банковских сетей, имеющих
          высшую степень защиты.</p>
        <img class="payment-logos mb-6" src="../assets/img/svg/payment_logos.svg">
        <p><strong>Уважаемые Клиенты, информируем Вас о том, что при запросе возврата денежных средств при отказе от
            покупки, возврат производится исключительно на ту же банковскую карту, с которой была произведена
            оплата</strong></p>
      </div>
    </section>
  </v-container>
</template>

<script>
  export default {

  }
</script>

<style>

</style>