<template>
  <div class="card">
    <div class="card__image">
      <img :src="img" alt="" @error="e => e.target.src = require('../assets/img/card_default.png')">
    </div>
    <div class="card__wr">
      <div style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; cursor: pointer;"
           @click="$router.push({ name: 'Product', params: { product_id: String(id) } })"
      ></div>
      <div class="card__top" style="width: 32px; margin-left: auto; z-index: 1">
        <div class="d-flex">
          <div class="d-flex flex-wrap">
            <v-chip class="custom-chip white--text mr-2 mb-2" color="orange" v-if="is_new === 1">
              Новинка
            </v-chip>
            <v-chip class="custom-chip white--text mr-2 mb-2" color="green" v-if="is_detox === 1">
              detox
            </v-chip>
          </div>
          <v-btn class="card__btn-favorite ml-auto"
                 :class="getAuthStatus ? {'active': isFavoriteProduct(id)} : null"
                 icon
                 @click="addFavorites">
            <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M12.0933 4.62091C9.73744 2.51016 6.11452 2.58673 3.85062 4.85062C1.50748 7.19377 1.50748 10.9928 3.85062 13.3359L10.679 20.1643C11.4601 20.9454 12.7264 20.9454 13.5075 20.1643L20.3359 13.3359C22.679 10.9928 22.679 7.19377 20.3359 4.85062C18.072 2.58673 14.4491 2.51016 12.0933 4.62091ZM10.9217 6.26483L11.3862 6.7293C11.7767 7.11982 12.4098 7.11982 12.8004 6.7293L13.2648 6.26483C14.8269 4.70274 17.3596 4.70274 18.9217 6.26483C20.4838 7.82693 20.4838 10.3596 18.9217 11.9217L12.0933 18.7501L5.26483 11.9217C3.70274 10.3596 3.70274 7.82693 5.26483 6.26483C6.82693 4.70274 9.35959 4.70274 10.9217 6.26483Z"/>
            </svg>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="card__content">
      <div class="d-flex mb-1">
        <router-link :to="{ name: 'Product', params: { product_id: id } }" class="card__title">
          <span>{{ title }}</span>
        </router-link>
        <v-btn class="card__chevron ml-auto" icon @click="toggleDesc" :class="{'rotate': !showDesc}" v-if="descr">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289L18.7071 13.2929C19.0976 13.6834 19.0976 14.3166 18.7071 14.7071C18.3166 15.0976 17.6834 15.0976 17.2929 14.7071L12 9.41421L6.70711 14.7071C6.31658 15.0976 5.68342 15.0976 5.29289 14.7071C4.90237 14.3166 4.90237 13.6834 5.29289 13.2929L11.2929 7.29289Z"
                fill="#0D0D0D"/>
          </svg>

        </v-btn>
      </div>
      <div class="pb-1">
        <slide-up-down :active="showDesc" :duration="300">
          <div class="card__desc" v-html="descr">
<!--              {{ descr }}-->
          </div>
        </slide-up-down>
      </div>
      <p class="card__weight mb-1 d-none">{{ weight + ' г' }}</p>
      <div class="d-flex align-center">
        <span class="card__price">{{ priceFormat(price) }}</span>
        <span class="card__price-old">
            {{ old_price === 0 ? '' : priceFormat(old_price) }}
          </span>
        <div class="d-flex align-center ml-auto">
          <v-btn class="card__count-btn" icon @click="productQuantity--">
            <span>—</span>
          </v-btn>
          <input class="card__count-input" value='1' type="text" readonly v-model:value="productQuantity">
          <v-btn class="card__count-btn" icon @click="productQuantity++">
            <span>+</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      showDesc: false,
      // productQuantity: 0,
    }
  },
  methods: {
    ...mapActions(['setOrderAction', 'appendFavoriteAction', 'removeFavoriteAction', 'setQuantityAction', 'dialogAuthAction', 'noAuthFavoriteAction']),
    toggleDesc() {
      this.showDesc = !this.showDesc
    },
    addFavorites() {
      if (!this.getUser) {
        this.dialogAuthAction({
          dialog: 'dialogLogin',
          status: true
        })
        this.noAuthFavoriteAction({
          id: this.id
        })
        return
      }

      const payload = {
        user_id: this.getUser.id,
        id: this.id
      };

      if(this.isFavoriteProduct(this.id)) {
        this.removeFavoriteAction(payload);
      } else {
        this.appendFavoriteAction(payload);
      }
    },
  },
  props: {
    img: String,
    title: String,
    descr: String,
    weight: Number,
    old_price: Number,
    price: Number,
    is_new: Number,
    is_detox: Number,
    id: Number,
  },
  computed: {
    ...mapGetters(['getSingleProduct', 'getOrder', 'getUser', 'isFavoriteProduct', 'getAuthStatus']),
    productQuantity: {
      get() {
        if (this.getOrder.length === 0) return 0;

        let index = this.getOrder.findIndex(elem => elem.product_id === this.id);
        return index === -1 ? 0 : this.getOrder[index].quantity;
      },
      set(value) {
        if (value >= 0) {
          this.setOrderAction({
            product: this.id,
            quantity: value,
            price: this.price,
            weight: this.weight,
          })
          if (value === 0) {
            let index = this.getOrder.findIndex(elem => elem.product_id === this.id)
            this.setQuantityAction({
              index: index
            })
          }
        }
      },
    },
    priceFormat() {
      return price => price === 0 ? 0 : price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' руб.';
    },
  },
}
</script>

<style>

</style>