<template>
  <v-container>
    <section class="order" v-if="getOrder">
      <v-row>
        <v-col class="mb-6 mb-sm-8" cols="12" lg="6">
          <h2 class="page-title mb-3 mb-sm-8">Ваш заказ</h2>
          <template v-for="(item, index) in getOrder">
            <div class="order-item">
              <div class="order-item__image-wr">
                <router-link :to="{ name: 'Product', params: { product_id: item.product_id } }">
                  <img :src="getSingleProduct(item.product_id).img" alt="" @error="e => e.target.src = require('../assets/img/card_default.png')">
                </router-link>
              </div>
              <div class="order-item__info">
                <router-link class="order-item__title" :to="{ name: 'Product', params: { product_id: item.product_id } }">
                  {{ getSingleProduct(item.product_id).title }}
                </router-link>
                <span class="order-item__weight">
                  {{ getSingleProduct(item.product_id).weight + ' г' }}
                </span>
                <span class="order-item__price">
                  {{ priceFormat(item.price) }}
                </span>
              </div>
              <div class="order-item__right">
                <span class='order-item__price-full'>
                  {{ priceFormat(item.price * item.quantity) }}
                </span>
                <div class="d-flex align-center justify-center">
                  <v-btn
                      class="order-item__count-btn"
                      icon
                      @click="selectProductQuantity('down', 1, index)"
                  >
                    <span>—</span>
                  </v-btn>
                  <input class="order-item__count-input"
                         value='3'
                         type="text"
                         readonly
                         v-model="item.quantity"
                  >
                  <v-btn
                      class="order-item__count-btn"
                      icon
                      @click="selectProductQuantity('up', 1, index)"
                  >
                    <span>+</span>
                  </v-btn>
                </div>
              </div>
            </div>
          </template>
          <hr class="order__delimiter">
          <div class="order__total">
            <span class="text-h5 mb-2">
              {{ 'Итого: ' + priceFormat(getOrderSum) }}
            </span>
            <span class="text-gray text-body-2">
              {{ 'Общий вес: ' + getTotalWeight + ' г' }}
            </span>
          </div>
        </v-col>
        <v-col cols="12" lg="6">
          <h2 class="page-title mb-3 mb-sm-8">Доставка</h2>
          <v-form class="order__form"
                  ref="form"
                  v-model="activeButton"
                  method='POST'
                  action='https://elegancecatering.server.paykeeper.ru/create/'
          >
            <div class="d-flex">
              <p class="text-18 mb-2 mr-3">Оплата:</p>
              <router-link :to="{ name: 'Payment' }" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                     fill="#000000">
                  <path d="M0 0h24v24H0V0z" fill="none"/>
                  <path
                      d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
                </svg>
              </router-link>
            </div>
            <v-radio-group
                class="custom-radio-group mb-0 mt-0"
                v-model="pay"
                column
            >
              <v-radio
                  class="custom-radio mb-3 mr-4 mr-sm-12"
                  label="Наличными курьеру"
                  value="cash"
              ></v-radio>
              <v-radio
                  class="custom-radio mb-3"
                  label="Картой онлайн"
                  value="online"
              ></v-radio>
              <div class="d-flex">
                <v-radio
                    class="custom-radio mb-3 mr-3"
                    label="Предварительный заказ"
                    value="preorder"
                ></v-radio>
                <v-tooltip right nudge-top="35" nudge-left="10">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                        v-bind="attrs"
                        v-on="on"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                           fill="#000000">
                        <path d="M0 0h24v24H0V0z" fill="none"/>
                        <path
                            d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
                      </svg>
                    </div>
                  </template>
                  <span>Наш менеджер свяжется с вами и обсудит заказ</span>
                </v-tooltip>
              </div>
            </v-radio-group>
            <v-text-field
                type="text"
                class="custom-input mb-3"
                v-model.trim="name"
                hide-details="auto"
                label="Ваше имя"
                filled
                required
                :rules="nameRules"
                name="clientid"
            ></v-text-field>
            <v-text-field
                type="text"
                class="custom-input mb-3"
                v-model="phone"
                hide-details="auto"
                label="Ваш номер телефона"
                filled
                required
                v-mask="'+7-(###)-###-##-##'"
                :rules="phoneRules"
                name="client_phone"
            ></v-text-field>
            <v-text-field
                type="email"
                class="custom-input mb-7"
                v-model="email"
                hide-details="auto"
                label="Ваш email"
                filled
                required
                :rules="emailRules"
                name="client_email"
            ></v-text-field>
            <p class="text-18 mb-2">Доставка:</p>
            <v-radio-group
                class="custom-radio-group delivery-radio-group mb-7 mt-0"
                v-model="delivery"
                row
            >
              <v-radio
                  class="custom-radio fix-margin mb-8 mb-sm-4 mr-4 mr-sm-12"
                  label="Курьером"
                  value="courier"
              >
                <template v-slot:label>
                  <div class="d-flex flex-column mb-n5">
                    <span>Курьером</span>
                    <span class="text-11 text-gray">Бесплатно</span>
                  </div>
                </template>
              </v-radio>
              <v-radio
                  class="custom-radio mb-4"
                  label="Самовывоз"
                  value="pickup"
              >
                <template v-slot:label>
                  <div class="d-flex flex-column mb-n5">
                    <span>Самовывоз</span>
                    <span class="text-11 text-gray">Москва, 105082, Переведеновский пер., д. 18, стр. 3, пом. I, этаж 1</span>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
            <p class="text-18 mb-2">Дата и время доставки:</p>
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="320px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    class="custom-input custom-input-date mb-3"
                    v-model="date"
                    label="Дата"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    :append-icon="'mdi-calendar-blank-outline'"
                    @click:append="modal = true"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date"
                  scrollable
                  :allowed-dates="disablePastDates"
                  locale="ru-RU"
                  first-day-of-week="1"
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-text-field
                type="time"
                class="custom-input mb-8 custom-input--time"
                v-model="time"
                hide-details="auto"
                label="Время (ЧЧ:ММ)"
                filled
                required
                :rules="timeRules"
            ></v-text-field>
            <v-textarea
                class="custom-textarea mb-3"
                solo
                v-model="comment"
                hide-details="auto"
                label="Комментарий к заказу"
                :rules="comment ? commentRules : undefined"
            ></v-textarea>
            <v-textarea
                class="custom-textarea mb-8"
                solo
                v-model="address"
                hide-details="auto"
                label="Адрес доставки"
                :rules="delivery === 'courier' ? addressRules : undefined"
                :disabled="delivery !== 'courier'"
            ></v-textarea>
            <v-text-field
                type="text"
                required
                v-show="false"
                name="sum"
                :value="getOrderSum"
            ></v-text-field>
            <v-text-field
                type="hidden"
                required
                v-show="false"
                name="orderid"
                :value="order_id"
            ></v-text-field>
            <v-text-field
                type="hidden"
                required
                v-show="false"
                name="cart"
                :value="JSON.stringify(getCart)"
            ></v-text-field>
            <v-btn
                class="btn-primary-md mb-5"
                x-large
                color="primary"
                rounded
                block
                :disabled="!activeButton"
                @click="makeOrder"
                type='button'
            >
              Заказать
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Order',
  mounted() {
    this.lastOrderDetailsAction()
  },
  data() {
    return {
      modal: false,
      activeButton: true,
      modal2: false,
      emailRules: [
        v => !!v || 'Это обязательное поле',
        v => /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,10}$/.test(v) || 'Введите валидный email',
      ],
      nameRules: [
        v => !!v || 'Это обязательное поле',
        v => /^[а-яА-Яa-zA-Z0-9]{1,15}$/.test(v) || 'Латинские и русские буквы и цифры от 2 до 15 символов',
      ],
      addressRules: [
        v => !!v || 'Это обязательное поле при доставке курьером',
        v => /^[а-яА-ЯёЁa-zA-Z0-9\s.\-,\n#№\/\\]+$/.test(v) || 'Русские и английские буквы и знаки препинания',
        v => (v && v.length <= 1000) || 'Максимальная длинна 1000 символов',
      ],
      commentRules: [
        // v => !!v || 'Это обязательное поле',
        v => /^[а-яА-ЯёЁa-zA-Z0-9\s.\-,\n#№\/\\!?:;'"()]+$/.test(v) || 'Русские и английские буквы и знаки препинания',
        v => (v && v.length <= 1000) || 'Максимальная длинна 1000 символов',
      ],
      phoneRules: [
        v => !!v || 'Это обязательное поле',
        v => /\+7-\([0-9]{3}\)-[0-9]{3}-[0-9]{2}-[0-9]{2}/.test(v) || 'Номер телефона введен не полностью'
      ],
      timeRules: [
        v => !!v || 'Это обязательное поле'
      ],
      formMethod: '',
      formAction: '',
      buttonType: 'button',
    }
  },
  methods: {
    ...mapActions(['setOrderAction', 'setQuantityAction', 'getProducts', 'setOrderDetailsAction', 'dialogAuthAction', 'sendOrderAction', 'clearOrderAction', 'getCartAction', 'lastOrderDetailsAction']),
    selectProductQuantity(direction, amount, index) {
      this.setQuantityAction({
        direction: direction,
        index: index,
        amount: amount,
      })
    },
    async makeOrder() {
      if (this.$refs.form.validate() && this.getOrderSum > 0) {
        
          await this.sendOrderAction()
          await this.getCartAction({
            order_id: this.order_id
          })
          if (this.pay === 'cash') {
            await this.$router.push({name: 'Index'})
            this.clearOrderAction()
          }
          if (this.pay === 'online') {
            this.$refs.form.$el.submit()
          }
          if (this.pay === 'preorder') {
            await this.$router.push({name: 'Index'})
            this.clearOrderAction()
          }
       
      }
    },
  },
  computed: {
    ...mapGetters(['allProducts', 'getOrder', 'getSingleProduct', 'getOrderSum', 'getTotalWeight', 'getOrderDetails', 'getUser', 'getAuthStatus', 'getCart']),
    pay: {
      get() {
        return this.getOrderDetails.pay
      },
      set(value) {
        this.setOrderDetailsAction({
          name: 'pay',
          value: value,
        })
      },
    },
    name: {
      get() {
        return this.getOrderDetails.name
      },
      set(value) {
        this.setOrderDetailsAction({
          name: 'name',
          value: value,
        })
      },
    },
    phone: {
      get() {
        return this.getOrderDetails.phone
      },
      set(value) {
        this.setOrderDetailsAction({
          name: 'phone',
          value: value,
        })
      },
    },
    email: {
      get() {
        return this.getOrderDetails.email
        // return this.getUser.email
      },
      set(value) {
        this.setOrderDetailsAction({
          name: 'email',
          value: value,
        })
      },
    },
    delivery: {
      get() {
        return this.getOrderDetails.delivery
      },
      set(value) {
        this.setOrderDetailsAction({
          name: 'delivery',
          value: value,
        })
      },
    },
    date: {
      get() {
        return this.getOrderDetails.date
      },
      set(value) {
        this.setOrderDetailsAction({
          name: 'date',
          value: value,
        })
      },
    },
    time: {
      get() {
        return this.getOrderDetails.time
      },
      set(value) {
        this.setOrderDetailsAction({
          name: 'time',
          value: value,
        })
      },
    },
    comment: {
      get() {
        return this.getOrderDetails.comment
      },
      set(value) {
        this.setOrderDetailsAction({
          name: 'comment',
          value: value,
        })
      },
    },
    address: {
      get() {
        return this.getOrderDetails.address
      },
      set(value) {
        this.setOrderDetailsAction({
          name: 'address',
          value: value,
        })
      },
    },
    order_id() {
        return this.getOrderDetails.order_id
    },
    priceFormat() {
      return price => price === 0 ? 0 : price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' руб.';
    },
    disablePastDates() {
      return val => val >= new Date().toISOString().substr(0, 10)
    },
  },
}
</script>
