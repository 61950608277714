import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Product from '../views/Product.vue'
import Profile from '../views/Profile.vue'
import Order from '../views/Order.vue'
import Payment from '../views/Payment.vue'
import PaymentDelivery from '../views/PaymentDelivery.vue'
import Contacts from '../views/Contacts.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      checkGetParams: true
    }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/product/:product_id',
    name: 'Product',
    component: Product
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: Order
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/payment-delivery',
    name: 'PaymentDelivery',
    component: PaymentDelivery
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: 'https://elegancecatering.ru/about/',
    name: 'About',
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/:notFound(.*)', redirect: '/'
  },
  {
    path: 'https://elegancecatering.ru/projects/',
    name: 'Projects',
    pathToRegexpOptions: { strict: true } 
  },
  {
    path: 'https://elegancecatering.ru/services/',
    name: 'Services',
    pathToRegexpOptions: { strict: true } 
  },
  // {
  //   path: 'https://elegancecatering.ru/about/articles/maks-2021/',
  //   name: 'MAKS2021',
  //   pathToRegexpOptions: { strict: true }
  // },
  {
    path: 'https://elegancecatering.ru/delivery/',
    name: 'Delivery',
    pathToRegexpOptions: { strict: true } 
  },
  // {
  //   path: 'https://elegancecatering.ru/about/articles/svadebny-banket/',
  //   name: 'Wedding',
  //   pathToRegexpOptions: { strict: true }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('checkAuthAction')
  if (store.state.allProducts.length === 0) {
    await store.dispatch('getProducts');
  }
  if (!store.getters.getFavoriteProducts) {
    await store.dispatch('getFavoritesAction');
  }
  if (!store.getters.getTextBanner) {
    await store.dispatch('textBannerAction')
  }
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (!store.getters.getAuthStatus) {
      store.dispatch('dialogAuthAction', {
        dialog: 'dialogLogin',
        status: true
      })
    } else {
      next()
    }
  } else {
    next()
  }
  if (to.matched.some(record => record.meta.checkGetParams)) {
    if (to.query.action === 'ConfirmEmail') {
      await store.dispatch('confirmEmailAction', {
        token: to.query.token
      })
      next({ path: '/', query: {} })
    }
    if (to.query.action === 'ConfirmRestorePassword') {
      await store.dispatch('dialogAuthAction', {
        dialog: 'dialogPass',
        status: true
      })
      store.state.newPassToken = to.query.token
      next({ path: '/', query: {} })
    }
    if (to.query.paid === 'yes') {
      store.state.order = []
      localStorage.removeItem('order')
      next({ path: '/', query: {} })
    }
    if (to.query.paid === 'no') {
      next({ name: 'Order', query: {} })
    }
    next()
  }
})

export default router
