<template>
  <header class="page-header" :class="{'page-header--index': $route.name === 'Index'}">
    <v-container class="position-relative">
      <div class="page-header__contacts">
        <div class="d-flex align-center mr-5">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2C7.6 2 4 5.6 4 10C4 15.4 11 21.5 11.3 21.8C11.5 21.9 11.8 22 12 22C12.2 22 12.5 21.9 12.7 21.8C13 21.5 20 15.4 20 10C20 5.6 16.4 2 12 2ZM12 19.7C9.9 17.7 6 13.4 6 10C6 6.7 8.7 4 12 4C15.3 4 18 6.7 18 10C18 13.3 14.1 17.7 12 19.7ZM12 6C9.8 6 8 7.8 8 10C8 12.2 9.8 14 12 14C14.2 14 16 12.2 16 10C16 7.8 14.2 6 12 6ZM12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12Z" fill="#0D0D0D"/>
          </svg>
          <span>Москва, Переведеновский пер. 18</span>
        </div>
        <div class="d-flex align-center mr-5">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.83301 4H8.32295L9.77016 7.61803L7.44528 9.16795C7.16708 9.35342 6.99998 9.66565 6.99998 10C7.00252 10.0936 6.99998 10.001 6.99998 10.001L6.99998 10.002L6.99999 10.0043L7.00002 10.0093L7.00017 10.0217C7.00032 10.0309 7.00057 10.0421 7.00099 10.0552C7.00184 10.0814 7.00337 10.1153 7.0061 10.1564C7.01158 10.2385 7.02189 10.3494 7.04128 10.4852C7.08004 10.7565 7.15526 11.1281 7.3013 11.5662C7.59453 12.4459 8.17071 13.5849 9.29288 14.7071C10.415 15.8293 11.554 16.4054 12.4338 16.6987C12.8719 16.8447 13.2435 16.9199 13.5148 16.9587C13.6505 16.9781 13.7615 16.9884 13.8436 16.9939C13.8847 16.9966 13.9186 16.9981 13.9448 16.999C13.9579 16.9994 13.9691 16.9997 13.9783 16.9998L13.9906 17L13.9957 17L13.9979 17L13.999 17C13.999 17 14.1106 16.9939 14 17C14.3788 17 14.725 16.786 14.8944 16.4472L15.5641 15.1078L20 15.8471V20.167C17.8889 20.4723 12.1874 20.7732 7.70709 16.2929C3.22679 11.8126 3.5277 6.1111 3.83301 4ZM9.07363 10.4861L10.8796 9.28213C11.6665 8.75751 11.9784 7.75338 11.6271 6.87525L10.1799 3.25722C9.87617 2.4979 9.14076 2 8.32295 2H3.78076C2.87225 2 2.01707 2.63116 1.86792 3.6169C1.52861 5.8595 1.06677 12.481 6.29288 17.7071C11.519 22.9332 18.1405 22.4714 20.3831 22.1321C21.3688 21.9829 22 21.1277 22 20.2192V15.8471C22 14.8694 21.2932 14.0351 20.3288 13.8743L15.8929 13.135C15.0268 12.9907 14.1679 13.4281 13.7753 14.2134L13.4288 14.9064C13.3199 14.8796 13.1983 14.8453 13.0662 14.8013C12.4459 14.5946 11.5849 14.1707 10.7071 13.2929C9.82925 12.4151 9.40543 11.5541 9.19867 10.9338C9.14279 10.7662 9.10257 10.6154 9.07363 10.4861Z" fill="#0D0D0D"/>
          </svg>
          <a href="tel:+74956624881">+7 (495) 66-24-881</a>&nbsp;
          <span>10.00 - 20.00</span>
        </div>
        <div class="d-flex align-center">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6ZM5.51859 6L12 11.6712L18.4814 6H5.51859ZM20 7.32877L12.6585 13.7526C12.2815 14.0825 11.7185 14.0825 11.3415 13.7526L4 7.32877V18H20V7.32877Z" fill="#0D0D0D"/>
          </svg>
          <a href="mailto:info@louvreelegance.com">info@louvreelegance.com</a>
        </div>
      </div>
      <img class="page-header__logo-luxury" src="../assets/img/logo.png" srcset="../assets/img/logo2x.png 2x" alt="" width="146" height="169">
      <div class="page-header__wr">
        <div class="page-header__links">
          <a class="mr-3 mr-md-5" href="tel:+74956624881">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.83301 4H8.32295L9.77016 7.61803L7.44528 9.16795C7.16708 9.35342 6.99998 9.66565 6.99998 10C7.00252 10.0936 6.99998 10.001 6.99998 10.001L6.99998 10.002L6.99999 10.0043L7.00002 10.0093L7.00017 10.0217C7.00032 10.0309 7.00057 10.0421 7.00099 10.0552C7.00184 10.0814 7.00337 10.1153 7.0061 10.1564C7.01158 10.2385 7.02189 10.3494 7.04128 10.4852C7.08004 10.7565 7.15526 11.1281 7.3013 11.5662C7.59453 12.4459 8.17071 13.5849 9.29288 14.7071C10.415 15.8293 11.554 16.4054 12.4338 16.6987C12.8719 16.8447 13.2435 16.9199 13.5148 16.9587C13.6505 16.9781 13.7615 16.9884 13.8436 16.9939C13.8847 16.9966 13.9186 16.9981 13.9448 16.999C13.9579 16.9994 13.9691 16.9997 13.9783 16.9998L13.9906 17L13.9957 17L13.9979 17L13.999 17C13.999 17 14.1106 16.9939 14 17C14.3788 17 14.725 16.786 14.8944 16.4472L15.5641 15.1078L20 15.8471V20.167C17.8889 20.4723 12.1874 20.7732 7.70709 16.2929C3.22679 11.8126 3.5277 6.1111 3.83301 4ZM9.07363 10.4861L10.8796 9.28213C11.6665 8.75751 11.9784 7.75338 11.6271 6.87525L10.1799 3.25722C9.87617 2.4979 9.14076 2 8.32295 2H3.78076C2.87225 2 2.01707 2.63116 1.86792 3.6169C1.52861 5.8595 1.06677 12.481 6.29288 17.7071C11.519 22.9332 18.1405 22.4714 20.3831 22.1321C21.3688 21.9829 22 21.1277 22 20.2192V15.8471C22 14.8694 21.2932 14.0351 20.3288 13.8743L15.8929 13.135C15.0268 12.9907 14.1679 13.4281 13.7753 14.2134L13.4288 14.9064C13.3199 14.8796 13.1983 14.8453 13.0662 14.8013C12.4459 14.5946 11.5849 14.1707 10.7071 13.2929C9.82925 12.4151 9.40543 11.5541 9.19867 10.9338C9.14279 10.7662 9.10257 10.6154 9.07363 10.4861Z" fill="#0D0D0D"/>
            </svg>
          </a>
          <router-link :to="{ name: 'Profile' }">
            <a class="d-flex align-center mr-3 mr-md-5" href="#">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4ZM6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8C18 11.3137 15.3137 14 12 14C8.68629 14 6 11.3137 6 8ZM8 18C6.34315 18 5 19.3431 5 21C5 21.5523 4.55228 22 4 22C3.44772 22 3 21.5523 3 21C3 18.2386 5.23858 16 8 16H16C18.7614 16 21 18.2386 21 21C21 21.5523 20.5523 22 20 22C19.4477 22 19 21.5523 19 21C19 19.3431 17.6569 18 16 18H8Z"/>
              </svg>
              <span class="d-none d-sm-block pe-2">{{ getUser.name ? getUser.name : 'Войти' }}</span>
            </a>
          </router-link>
          <router-link :to="{ name: 'Order' }">
            <a class="d-flex align-center" href="#">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.1421 3.99989L6.00913 16.1356C6.02271 16.2357 6.05113 16.3312 6.0921 16.4197C6.21543 16.6859 6.45246 16.8888 6.74088 16.966C6.82899 16.9897 6.92133 17.0015 7.01578 16.9999H18C18.4416 16.9999 18.8309 16.7102 18.9578 16.2872L21.9578 6.28724C22.0487 5.98447 21.991 5.65657 21.8023 5.40296C21.6136 5.14935 21.3161 4.99989 21 4.99989H6.31948L5.99058 2.86209C5.97826 2.7727 5.95413 2.68709 5.91981 2.60687C5.85751 2.4608 5.76213 2.33426 5.64429 2.23508C5.53497 2.14289 5.40561 2.07372 5.26367 2.03502C5.17434 2.01056 5.0806 1.9983 4.9847 1.99989H3C2.44772 1.99989 2 2.44761 2 2.99989C2 3.55218 2.44772 3.99989 3 3.99989H4.1421ZM7.85794 14.9999L6.62717 6.99989H19.656L17.256 14.9999H7.85794Z"/>
                <path d="M10 19.9999C10 21.1045 9.10457 21.9999 8 21.9999C6.89543 21.9999 6 21.1045 6 19.9999C6 18.8953 6.89543 17.9999 8 17.9999C9.10457 17.9999 10 18.8953 10 19.9999Z"/>
                <path d="M19 19.9999C19 21.1045 18.1046 21.9999 17 21.9999C15.8954 21.9999 15 21.1045 15 19.9999C15 18.8953 15.8954 17.9999 17 17.9999C18.1046 17.9999 19 18.8953 19 19.9999Z"/>
              </svg>
              <span>{{ priceFormat(getOrderSum) }}</span>
            </a>
          </router-link>
          <a v-if="getAuthStatus" class="ml-3" @click="logout">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="path-not-fill" fill-rule="evenodd" clip-rule="evenodd" d="M11 20C11 19.4477 10.5523 19 10 19H5V5H10C10.5523 5 11 4.55228 11 4C11 3.44771 10.5523 3 10 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H10C10.5523 21 11 20.5523 11 20Z" fill="black"/>
              <path class="path-not-fill" d="M21.7136 12.7005C21.8063 12.6062 21.8764 12.498 21.9241 12.3828C21.9727 12.2657 21.9996 12.1375 22 12.003L22 12L22 11.997C21.9992 11.7421 21.9016 11.4874 21.7071 11.2929L17.7071 7.29289C17.3166 6.90237 16.6834 6.90237 16.2929 7.29289C15.9024 7.68342 15.9024 8.31658 16.2929 8.70711L18.5858 11H9C8.44771 11 8 11.4477 8 12C8 12.5523 8.44771 13 9 13H18.5858L16.2929 15.2929C15.9024 15.6834 15.9024 16.3166 16.2929 16.7071C16.6834 17.0976 17.3166 17.0976 17.7071 16.7071L21.7064 12.7078L21.7136 12.7005Z" fill="black"/>
            </svg>
          </a>
          <a v-if="!menuOpen" class="ml-3 d-flex d-md-none" @click="toggleMenu">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H5C4.44772 8 4 7.55228 4 7ZM4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12ZM4 17C4 16.4477 4.44772 16 5 16H19C19.5523 16 20 16.4477 20 17C20 17.5523 19.5523 18 19 18H5C4.44772 18 4 17.5523 4 17Z"/>
            </svg>
          </a>
          <a v-if="menuOpen" class="close ml-3 d-flex d-md-none" @click="toggleMenu">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="path-not-fill" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="white"/>
            </svg>
          </a>
        </div>
        <a href="https://elegancecatering.ru/" class="page-header__logo-elegance">
          <img src="../assets/img/logo_elegance.png" alt="Elegance" width="324" height="136">
        </a>
        <div class="page-header__links-second">
          <a class="mr-3 mr-md-2" href="https://t.me/elegance_catering" target="_blank">
            <svg fill="#E61873" height="24" width="24" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 455 455" space="preserve" style="border-radius: 6px;">
              <g>
                <path style="fill-rule:evenodd;clip-rule:evenodd;" d="M0,0v455h455V0H0z M384.814,100.68l-53.458,257.136
                  c-1.259,6.071-8.378,8.822-13.401,5.172l-72.975-52.981c-4.43-3.217-10.471-3.046-14.712,0.412l-40.46,32.981
                  c-4.695,3.84-11.771,1.7-13.569-4.083l-28.094-90.351l-72.583-27.089c-7.373-2.762-7.436-13.171-0.084-16.003L373.36,90.959
                  C379.675,88.517,386.19,94.049,384.814,100.68z"></path>
                <path style="fill-rule:evenodd;clip-rule:evenodd;" d="M313.567,147.179l-141.854,87.367c-5.437,3.355-7.996,9.921-6.242,16.068
                  l15.337,53.891c1.091,3.818,6.631,3.428,7.162-0.517l3.986-29.553c0.753-5.564,3.406-10.693,7.522-14.522l117.069-108.822
                  C318.739,149.061,316.115,145.614,313.567,147.179z"></path>
              </g>
            </svg>
          </a>
          <a class="mr-3 mr-md-2" href="https://vk.com/elegancecatering" target="_blank">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.68 1.68C0 3.376 0 6.088 0 11.52V12.48C0 17.904 0 20.616 1.68 22.32C3.376 24 6.088 24 11.52 24H12.48C17.904 24 20.616 24 22.32 22.32C24 20.624 24 17.912 24 12.48V11.52C24 6.096 24 3.384 22.32 1.68C20.624 0 17.912 0 12.48 0H11.52C6.096 0 3.384 0 1.68 1.68ZM4.0481 7.30398C4.1761 13.544 7.2961 17.288 12.7681 17.288H12.7761H13.0881V13.72C15.0961 13.92 16.6161 15.392 17.2241 17.288H20.0641C19.7728 16.2106 19.2564 15.2072 18.549 14.344C17.8416 13.4807 16.9592 12.7773 15.9601 12.28C16.8554 11.7348 17.6287 11.0108 18.2316 10.1532C18.8345 9.29566 19.2541 8.32299 19.4641 7.29598H16.8881C16.3281 9.27998 14.6641 11.08 13.0881 11.248V7.30398H10.5041V14.216C8.9041 13.816 6.8881 11.88 6.8001 7.30398H4.0481Z" fill="#E61873"/>
            </svg>
          </a>
          <router-link :to="{ name: 'Order' }">
            <v-btn
                rounded
                color="primary"
                elevation="0"
                class="btn-primary"
            >
              Заказать сейчас
            </v-btn>
          </router-link>
        </div>
      </div>
      <nav class="page-header__nav" :class="{'open': menuOpen}">
        <ul class="page-header__menu">
          <li>
            <router-link :to="{ name: 'About' }" target="_blank">О компании</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Projects' }" target="_blank">Наши проекты</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Services' }" target="_blank">Наши услуги</router-link>
          </li>
<!--          <li>-->
<!--            <router-link :to="{ name: 'MAKS2021' }" target="_blank">МАКС 2021</router-link>-->
<!--          </li>-->
          <li>
            <router-link :to="{ name: 'Delivery' }" target="_blank">Доставка блюд</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Contacts' }">Контакты</router-link>
          </li>
<!--          <li>-->
<!--            <router-link :to="{ name: 'Wedding' }" target="_blank">Свадебный банкет</router-link>-->
<!--          </li>-->
        </ul>
      <div class="page-header__contacts-mobile">
        <div class="d-flex align-center mb-4">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2C7.6 2 4 5.6 4 10C4 15.4 11 21.5 11.3 21.8C11.5 21.9 11.8 22 12 22C12.2 22 12.5 21.9 12.7 21.8C13 21.5 20 15.4 20 10C20 5.6 16.4 2 12 2ZM12 19.7C9.9 17.7 6 13.4 6 10C6 6.7 8.7 4 12 4C15.3 4 18 6.7 18 10C18 13.3 14.1 17.7 12 19.7ZM12 6C9.8 6 8 7.8 8 10C8 12.2 9.8 14 12 14C14.2 14 16 12.2 16 10C16 7.8 14.2 6 12 6ZM12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12Z" fill="#0D0D0D"/>
          </svg>
          <span>Москва, Переведеновский пер. 18</span>
        </div>
        <div class="d-flex align-center mb-4">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.83301 4H8.32295L9.77016 7.61803L7.44528 9.16795C7.16708 9.35342 6.99998 9.66565 6.99998 10C7.00252 10.0936 6.99998 10.001 6.99998 10.001L6.99998 10.002L6.99999 10.0043L7.00002 10.0093L7.00017 10.0217C7.00032 10.0309 7.00057 10.0421 7.00099 10.0552C7.00184 10.0814 7.00337 10.1153 7.0061 10.1564C7.01158 10.2385 7.02189 10.3494 7.04128 10.4852C7.08004 10.7565 7.15526 11.1281 7.3013 11.5662C7.59453 12.4459 8.17071 13.5849 9.29288 14.7071C10.415 15.8293 11.554 16.4054 12.4338 16.6987C12.8719 16.8447 13.2435 16.9199 13.5148 16.9587C13.6505 16.9781 13.7615 16.9884 13.8436 16.9939C13.8847 16.9966 13.9186 16.9981 13.9448 16.999C13.9579 16.9994 13.9691 16.9997 13.9783 16.9998L13.9906 17L13.9957 17L13.9979 17L13.999 17C13.999 17 14.1106 16.9939 14 17C14.3788 17 14.725 16.786 14.8944 16.4472L15.5641 15.1078L20 15.8471V20.167C17.8889 20.4723 12.1874 20.7732 7.70709 16.2929C3.22679 11.8126 3.5277 6.1111 3.83301 4ZM9.07363 10.4861L10.8796 9.28213C11.6665 8.75751 11.9784 7.75338 11.6271 6.87525L10.1799 3.25722C9.87617 2.4979 9.14076 2 8.32295 2H3.78076C2.87225 2 2.01707 2.63116 1.86792 3.6169C1.52861 5.8595 1.06677 12.481 6.29288 17.7071C11.519 22.9332 18.1405 22.4714 20.3831 22.1321C21.3688 21.9829 22 21.1277 22 20.2192V15.8471C22 14.8694 21.2932 14.0351 20.3288 13.8743L15.8929 13.135C15.0268 12.9907 14.1679 13.4281 13.7753 14.2134L13.4288 14.9064C13.3199 14.8796 13.1983 14.8453 13.0662 14.8013C12.4459 14.5946 11.5849 14.1707 10.7071 13.2929C9.82925 12.4151 9.40543 11.5541 9.19867 10.9338C9.14279 10.7662 9.10257 10.6154 9.07363 10.4861Z" fill="#0D0D0D"/>
          </svg>
          <a href="tel:+74956624881">+7 (495) 66-24-881</a>&nbsp;
          <span>10.00 - 20.00</span>
        </div>
        <div class="d-flex align-center">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6ZM5.51859 6L12 11.6712L18.4814 6H5.51859ZM20 7.32877L12.6585 13.7526C12.2815 14.0825 11.7185 14.0825 11.3415 13.7526L4 7.32877V18H20V7.32877Z" fill="#0D0D0D"/>
          </svg>
          <a href="mailto:info@louvreelegance.com">info@louvreelegance.com</a>
        </div>
      </div>
      </nav>
    </v-container>
  </header>
</template>

<script>
import {mapGetters, mapActions} from "vuex"

export default {
  data: () => ({
    menuOpen: false
  }),
  mounted() {
    // if (localStorage.getItem('user')) {
    //   try {
    //     this.setUserAction({
    //       user: localStorage.getItem('user')
    //     })
    //   } catch(e) {
    //     localStorage.removeItem('user');
    //   }
    // }
    if (localStorage.getItem('order')) {
      this.setOrderAction({
        order: JSON.parse(localStorage.getItem('order')),
        fromStorage: true
      })
    }
  },
  methods: {
    ...mapActions(['setUserAction', 'logoutAction', 'checkAuthAction', 'setOrderAction']),
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
    logout() {
      this.logoutAction({
        currentRoute: this.$route.name
      })
    }
  },
  computed: {
    ...mapGetters(['getOrderSum', 'getUser', 'getAuthStatus', 'getOrder']),
    priceFormat() {
      return price => price === 0 ? 0 : price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ₽';
    },
  },
}
</script>

<style>

</style>