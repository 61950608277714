<template>
  <v-dialog
      v-model="dialogLogin"
      max-width="510"
  >
    <div class="modal">
      <img class="d-block mt-5 mb-7 mx-auto" src="../assets/img/logo_elegance.png"
           srcset="../assets/img/logo_elegance2x.png 2x" alt="" width="205" height="86">
      <h5 class="text-h5 text-center mb-2">Авторизация</h5>
      <p class="text-subtitle-2 text-center">Мы рады вас видеть</p>
      <v-form class="mt-6" v-model="valid" ref="form">
        <v-text-field
            type="email"
            class="custom-input mb-5"
            v-model.trim="loginEmail"
            hide-details="auto"
            label="Ваш email"
            filled
            required
            :rules="emailRules"
            validate-on-blur
            @input="clearUserMessage"
        ></v-text-field>
        <v-text-field
            class="custom-input mb-5"
            v-model="loginPassword"
            hide-details="auto"
            filled
            :append-icon="showLoginPassword ? 'mdi-eye-off' : 'mdi-eye'"
            label="Введите пароль"
            :type="showLoginPassword ? 'text' : 'password'"
            @click:append="showLoginPassword = !showLoginPassword"
            required
            :rules="loginPasswordRules"
            :error-messages="getUserMessages.statusEmail ? getUserMessages.text : null"
            @input="clearUserMessage"
        ></v-text-field>
        <v-btn
            class="btn-primary-md mb-5"
            :disabled="!valid"
            x-large
            color="primary"
            rounded
            block
            @click="login"
        >
          Войти
        </v-btn>
        <div class="d-flex align-center justify-space-between">
          <a class="text-body-2 black--text" href="#" @click.prevent="openRegisterDialog">Зарегистрироваться</a>
          <a class="text-body-2 black--text" href="#" @click.prevent="openRestoreDialog">Забыл пароль?</a>
        </div>
      </v-form>
      <v-btn
          @click="dialogLogin = false"
          class="modal__close custom-icon-btn primary"
          icon
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill="#ffffff"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"/>
        </svg>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      loginEmail: '',
      loginPassword: '',
      showLoginPassword: false,
      valid: true,
      emailRules: [
        v => !!v || 'Это обязательное поле',
        v => /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,10}$/.test(v) || 'Введите валидный email'
      ],
      loginPasswordRules: [
        v => !!v || 'Это обязательное поле',
        v => /^.{6,}$/.test(v) || 'Пароль 6 символов'
      ],
    }
  },
  methods: {
    ...mapActions(['loginAction', 'dialogAuthAction']),
    ...mapMutations(['setUserMessage']),
    login() {
      if (this.$refs.form.validate()) {
        this.loginAction({
          email: this.loginEmail,
          password: this.loginPassword
        })
      }
    },
    openRegisterDialog() {
      this.dialogLogin = false
      this.dialogAuthAction({
        dialog: 'dialogReg',
        status: true
      })
    },
    openRestoreDialog() {
      this.dialogLogin = false
      this.dialogAuthAction({
        dialog: 'dialogRec',
        status: true
      })
    },
    clearUserMessage() {
      this.setUserMessage({
        statusPhone: false,
        statusEmail: false,
        text: ''
      })
    }
  },
  computed: {
    ...mapGetters(['getAuthDialogs', 'getUserMessages']),
    dialogLogin: {
      get() {
       return  this.getAuthDialogs.dialogLogin
    },
      set(value) {
        this.dialogAuthAction({
          dialog: 'dialogLogin',
          status: value
        })
      }
    }
  }
}
</script>

<style scoped>

</style>